import React from "react";

const TransportServiceArea = () => {
  return (
    <>
      {/* Transport service area start */}

      <div>
        <div
          className=""
          style={{
            backgroundColor: "rgba(8, 12, 36, 0.9)",
            padding: 20,
            color: "white",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title  text-center">
                  <span className="subtitle">Our Services</span>
                  <h2 className="title" style={{ color: "white" }}>
                    What We Can Do For You
                  </h2>
                </div>
              </div>
            </div>
            <div className="row transport-service-single">
              <div className="col-lg-6">
                <div className="transport-service-image wow fadeInLeft">
                  <img
                    src="assets/img/service/01.png"
                    className="img-fluid"
                    alt="transport-service images"
                  />
                  <div className="transport-service-item-hover">
                    <a href="#">
                      <div className="transport-service-link">
                        <span className="fa fa-eye" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 offset-lg-1 d-flex">
                <div className="transport-service-content wow fadeInRight">
                  <h3 className="title" style={{ color: "white" }}>
                    Transparent Pricing
                  </h3>
                  <p>
                    At TransPro, we believe in transparent pricing. Our
                    straightforward approach ensures you know exactly what
                    you're paying for. Trust us for clear and honest pricing,
                    making your logistics decisions easier.
                  </p>
                  <div className="btn-wrapper animated fadeInUpBig text-left">
                    <a href="#" className="boxed-btn blank">
                      Contact for transport
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row transport-service-single">
              <div className="col-lg-6 offset-lg-1 order-lg-2">
                <div className="transport-service-image wow fadeInRight">
                  <img
                    src="assets/img/service/02.png"
                    className="img-fluid"
                    alt="transport-service images"
                  />
                  <div className="transport-service-item-hover">
                    <a href="#">
                      <div className="transport-service-link">
                        <span className="fa fa-eye" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 offset-lg-1 d-flex ">
                <div className="transport-service-content wow fadeInLeft">
                  <h3 className="title" style={{ color: "white" }}>
                    Tracking For Your Shipment
                  </h3>
                  <p>
                    Stay informed every step of the way with TransPro's robust
                    tracking feature. From pick-up to delivery, monitor your
                    shipment's progress effortlessly with real-time updates and
                    notifications..
                  </p>
                  <div className="btn-wrapper animated fadeInUpBig text-left">
                    <a href="#" className="boxed-btn blank">
                      Contact for transport
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row transport-service-single">
              <div className="col-lg-6">
                <div className="transport-service-image wow fadeInLeft">
                  <img
                    src="assets/img/service/03.png"
                    className="img-fluid"
                    alt="transport-service images"
                  />
                  <div className="transport-service-item-hover">
                    <a href="#">
                      <div className="transport-service-link">
                        <span className="fa fa-eye" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 offset-lg-1 d-flex">
                <div className="transport-service-content wow fadeInRight">
                  <h3 className="title" style={{ color: "white" }}>
                    On-Time Delivery
                  </h3>
                  <p>
                    Count on TransPro for reliable on-time deliveries, ensuring
                    your shipments reach their destination promptly and
                    efficiently.
                  </p>
                  <div className="btn-wrapper animated fadeInUpBig text-left">
                    <a href="#" className="boxed-btn blank">
                      Contact for transport
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-2">
                <div className="btn-wrapper animated fadeInUpBig text-center">
                  <a href="/service" className="boxed-btn btn-bounce">
                    View All Services
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Transport service area end */}
    </>
  );
};

export default TransportServiceArea;
